import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import ContentDefault from './ContentDefault'
import ContentImage from './ContentImage'
import ContentUsp from './ContentUsp'
import ContentHouses from './ContentHouses'
import ContentBigImage from './ContentBigImage'
import ContentCharacter from './ContentCharacter'
import ContentSmallImage from './ContentSmallImage'
import ContentDual from './ContentDual'
import ContentDownloads from './ContentDownloads'

export default function ContentShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: ContentDefault,
    image: ContentImage,
    smallimage: ContentSmallImage,
    usp: ContentUsp,
    houses: ContentHouses,
    bigimage: ContentBigImage,
    character: ContentCharacter,
    dual: ContentDual,
    downloads: ContentDownloads
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
