import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useInView } from 'framer-motion'
import { useRef } from 'react'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentSmallImage({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const contentRef = useRef(null)
  const imageRef = useRef(null)

  const contentVisible = useInView(contentRef, { once: true })
  const imageVisible = useInView(imageRef, { once: true })

  return (
    <section data-component="ContentSmallImage" className="section z-10 max-lg:overflow-hidden relative">
      <div className="container">
        <div className="gap-7 lg:gap-20 grid grid-cols-1 lg:grid-cols-2">
          {fields.imageposition === 'left' && (
            <div
              ref={imageRef}
              className="absolute max-lg:w-[400px] max-sm:-right-32 max-lg:-right-10 max-lg:h-full lg:relative max-lg:opacity-20"
            >
              <svg
                className="absolute pointer-events-none w-[300px] sm:w-[400px] xl:w-[500px] -top-40 xl:-top-32 right-0"
                width="556"
                height="731"
                viewBox="0 0 556 731"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.2"
                  d="M382.886 491.411L301.421 582.149L221.993 102.972C218.598 82.5816 214.864 68.6481 210.791 61.1716C206.718 53.0154 200.269 48.9373 191.443 48.9373C184.654 48.9373 176.847 54.7146 168.022 66.2692C159.197 77.1441 148.335 100.593 135.436 136.616L121.179 178.417H101.831L127.289 103.992C141.546 62.5309 159.197 35.0037 180.242 21.4101C201.287 7.1367 223.35 0 246.432 0C270.193 0 288.523 7.1367 301.421 21.4101C314.999 35.0037 324.503 60.8317 329.934 98.894L382.886 491.411ZM152.747 682.063C183.297 666.43 215.204 645.02 248.469 617.833C281.734 591.325 316.017 555.302 351.319 509.763C390.694 460.146 425.995 405.092 457.223 344.6C488.452 284.108 510.855 223.956 524.432 164.144C511.534 156.667 498.296 147.831 484.718 137.636C471.819 126.761 460.957 114.527 452.132 100.933C443.985 87.3394 440.252 72.0465 440.93 55.0544C442.288 37.3826 448.059 23.7889 458.242 14.2734C468.425 4.75779 482.002 0 498.974 0C518.662 0 532.918 7.47651 541.744 22.4296C551.248 36.7029 556 57.7731 556 85.6402C556 119.624 550.23 156.327 538.689 195.749C527.827 234.491 512.552 274.252 492.864 315.033C473.856 355.815 452.471 395.236 428.711 433.298C404.95 470.681 380.51 505.005 355.392 536.271C341.136 553.942 323.145 573.313 301.421 594.383C280.376 616.133 256.276 637.204 229.121 657.594C202.645 677.985 173.114 694.977 140.527 708.57C122.198 716.727 105.905 722.504 91.6483 725.902C78.0708 729.301 66.8693 731 58.0439 731C41.7509 731 28.1734 726.922 17.3113 718.766C5.77044 710.609 0 699.055 0 684.102C0 668.469 6.10989 655.895 18.3297 646.379C29.8706 636.864 43.7875 632.106 60.0806 632.106C77.7314 632.106 94.0244 636.864 108.96 646.379C124.574 656.575 139.17 668.469 152.747 682.063Z"
                  fill="#7E826A"
                />
              </svg>

              <LossePlaatjie
                maxwidth={1000}
                className={clsx(
                  imageVisible && 'animate-fade-up animate-delay-300 animate-duration-500 animate-ease-in-out',
                  imageVisible ? 'opacity-100' : 'opacity-0',
                  'absolute mix-blend-multiply lg:left-20 xl:left-24 -top-10 sm:-top-20'
                )}
                src={fields.image}
              />
            </div>
          )}

          <div
            ref={contentRef}
            className={clsx(
              contentVisible && 'animate-fade-up animate-delay-100 animate-duration-500 animate-ease-in-out',
              contentVisible ? 'opacity-100' : 'opacity-0',
              fields.imageposition === 'left' ? 'xl:pl-20' : 'xl:pl-32'
            )}
          >
            <div className="pb-5 sm:pb-14">
              <h2 className="text-3xl sm:text-4xl xl:text-[44px] font-bold text-site-text children-strong:font-bold font-site-accent">
                {fields.title}
              </h2>
            </div>
            <div className={clsx(fields.imageposition === 'right' ? '' : 'xl:pr-24')}>
              <Content className="content">{fields.description}</Content>
              {fields.links && (
                <div className="flex items-center gap-2 mt-6 sm:mt-8">
                  {fields.links.map((link, index) => (
                    <Button className={clsx(index >= 1 && 'btn--alt', 'btn--alt')} key={index} to={link?.link?.url || '/'}>
                      {link?.link?.title}
                    </Button>
                  ))}
                </div>
              )}
            </div>
          </div>

          {fields.imageposition === 'right' && (
            <div ref={imageRef} className="flex items-center justify-center">
              <svg
                className="absolute pointer-events-none z-10 w-[300px] sm:w-[400px] xl:w-[700px] -top-10 xl:-top-40 -right-8"
                width="754"
                height="785"
                viewBox="0 0 754 785"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.2"
                  d="M377.533 0C452.898 0 518.664 15.2876 574.832 45.8628C631.71 76.438 675.792 120.879 707.075 179.185C738.358 236.78 754 305.752 754 386.101C754 464.316 738.003 533.644 706.008 594.083C674.014 654.522 629.222 701.452 571.632 734.871C514.753 768.29 449.698 785 376.467 785C301.102 785 234.981 769.712 178.102 739.137C121.934 708.562 78.2084 664.477 46.925 606.882C15.6417 548.576 0 479.248 0 398.899C0 320.684 15.9972 251.356 47.9915 190.917C79.9858 130.478 124.422 83.5485 181.301 50.1291C238.891 16.7097 304.302 0 377.533 0ZM373.267 19.1984C334.163 19.1984 299.68 35.197 269.819 67.1943C239.958 98.4805 216.851 142.566 200.498 199.45C184.856 256.334 177.035 321.395 177.035 394.633C177.035 468.582 185.923 533.644 203.697 589.817C222.183 645.99 246.712 689.364 277.284 719.939C308.568 750.514 343.05 765.802 380.733 765.802C419.837 765.802 454.32 750.159 484.181 718.872C514.042 686.875 536.794 642.434 552.436 585.55C568.788 528.666 576.965 463.605 576.965 390.367C576.965 316.418 567.722 251.356 549.236 195.183C531.462 139.01 506.933 95.6363 475.649 65.0612C445.077 34.486 410.95 19.1984 373.267 19.1984Z"
                  fill="#D29F89"
                />
              </svg>

              <LossePlaatjie
                maxwidth={600}
                className={clsx(
                  imageVisible && 'animate-fade-up animate-delay-300 animate-duration-500 animate-ease-in-out',
                  imageVisible ? 'opacity-100' : 'opacity-0',
                  'z-20 relative w-full max-w-[570px]'
                )}
                src={fields.image}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
