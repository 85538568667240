import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import { LossePlaatjie } from '@ubo/losse-sjedel'

export default function BannerDefault({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section data-component="BannerDefault">
      <div className="container section">
        <div className="grid grid-cols-2 lg:grid-cols-5 gap-5 sm:gap-8 lg:gap-16 xl:gap-28">
          <div className="col-span-2 flex items-end lg:pb-6 xl:pb-14">
            {fields?.title && (
              <div className="animate-fade-right animate-delay-100 animate-duration-500 animate-ease-in-out">
                <h1 className="!leading-[1.2] text-3xl sm:text-5xl font-bold font-site-accent">{fields?.title}</h1>
              </div>
            )}
          </div>
          <div className="col-span-2 lg:col-span-3">
            {fields?.image && (
              <div className="lg:h-[500px] xl:h-[550px] w-full overflow-hidden relative">
                <LossePlaatjie
                  loading="eager"
                  placeholder="blur"
                  className="w-full animate-fade-up animate-duration-500 animate-ease-in-out h-full object-cover"
                  src={fields.image}
                  maxwidth={1920}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
