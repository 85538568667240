import { LosseBlogBink } from '@ubo/losse-sjedel'
import type { ContentNode, Maybe, Page_Flexcontent_Flex_Posts, WpPageInfo } from '~/graphql/types'
import Post from './post-types/Post'

export default function NewsOverview({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  if (!fields.posts?.pageInfo) {
    return null
  }

  return (
    <section data-component="NewsOverview" className="section">
      <div className="container relative sm:py-8 md:py-16">
        <div className="animate-fade-up animate-duration-500 relative z-10 animate-ease-in-out">
          <h1 className="text-5xl sm:text-7xl md:text-9xl font-site-accent font-bold text-site-text">{fields.title}</h1>
          <h2 className="text-2xl italic xs:text-3xl sm:text-5xl md:text-6xl whitespace-nowrap font-site-accent pl-[100px] md:pl-[240px] text-site-text">
            {fields.subtitle}
          </h2>
        </div>
        <div className="max-w-screen-lg animate-fade-up animate-delay-300 animate-duration-500 animate-ease-in-out relative z-10 pt-6 sm:pt-10 md:pt-20 mx-auto">
          <LosseBlogBink pageInfo={fields.posts?.pageInfo as Maybe<WpPageInfo> | undefined}>
            <Overview fields={fields} />
          </LosseBlogBink>
        </div>
        <svg
          className="top-0 w-[300px] md:w-[556px] right-0 absolute animate-fade-up animate-delay-500 animate-duration-500 animate-ease-in-out"
          viewBox="0 0 556 731"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.2"
            d="M382.886 491.411L301.421 582.149L221.993 102.972C218.598 82.5816 214.864 68.6481 210.791 61.1716C206.718 53.0154 200.269 48.9373 191.443 48.9373C184.654 48.9373 176.847 54.7146 168.022 66.2692C159.197 77.1441 148.335 100.593 135.436 136.616L121.179 178.417H101.831L127.289 103.992C141.546 62.5309 159.197 35.0037 180.242 21.4101C201.287 7.1367 223.35 0 246.432 0C270.193 0 288.523 7.1367 301.421 21.4101C314.999 35.0037 324.503 60.8317 329.934 98.894L382.886 491.411ZM152.747 682.063C183.297 666.43 215.204 645.02 248.469 617.833C281.734 591.325 316.017 555.302 351.319 509.763C390.694 460.146 425.995 405.092 457.223 344.6C488.452 284.108 510.855 223.956 524.432 164.144C511.534 156.667 498.296 147.831 484.718 137.636C471.819 126.761 460.957 114.527 452.132 100.933C443.985 87.3394 440.252 72.0465 440.93 55.0544C442.288 37.3826 448.059 23.7889 458.242 14.2734C468.425 4.75779 482.002 0 498.974 0C518.662 0 532.918 7.47651 541.744 22.4296C551.248 36.7029 556 57.7731 556 85.6402C556 119.624 550.23 156.327 538.689 195.749C527.827 234.491 512.552 274.252 492.864 315.033C473.856 355.815 452.471 395.236 428.711 433.298C404.95 470.681 380.51 505.005 355.392 536.271C341.136 553.942 323.145 573.313 301.421 594.383C280.376 616.133 256.276 637.204 229.121 657.594C202.645 677.985 173.114 694.977 140.527 708.57C122.198 716.727 105.905 722.504 91.6483 725.902C78.0708 729.301 66.8693 731 58.0439 731C41.7509 731 28.1734 726.922 17.3113 718.766C5.77044 710.609 0 699.055 0 684.102C0 668.469 6.10989 655.895 18.3297 646.379C29.8706 636.864 43.7875 632.106 60.0806 632.106C77.7314 632.106 94.0244 636.864 108.96 646.379C124.574 656.575 139.17 668.469 152.747 682.063Z"
            fill="#7E826A"
          />
        </svg>
      </div>
    </section>
  )
}

type Edge<T = ContentNode> = {
  node: T & {
    __typename: string
  }
}

const getPostTypeName = (fields: Page_Flexcontent_Flex_Posts) => {
  const items = fields.posts?.edges as Edge[]
  return items?.[0]?.node?.__typename
}

const PostTypes: { [key: string]: any } = {
  Post: Post
}

function Overview({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const postTypeName = getPostTypeName(fields)

  return (
    <>
      <div className="grid grid-cols-1 xs:grid-cols-2 gap-7 xs:gap-4 sm:gap-6 md:gap-12">
        {fields.posts.edges?.map((edge) => {
          if (!edge || !edge.node) return null

          const Component = PostTypes[postTypeName] || PostTypes.Post

          return (
            <div key={edge.node.uri} className="col-span-1">
              <Component data={edge.node} />
            </div>
          )
        })}
      </div>
    </>
  )
}
