import { LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'

export default function BannerDetail({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section data-component="BannerDetail" className="relative z-30">
      <div className="max-w-[2000px] sm:mb-[200px] md:mb-[120px] sm:h-[460px] md:h-[550px] xl:h-[665px] mx-auto flex sm:flex-row flex-col items-end relative">
        <LossePlaatjie
          loading="eager"
          maxwidth={2000}
          src={fields.image}
          className="animate-fade-up max-sm:min-h-[350px] animate-duration-500 animate-ease-in-out sm:absolute h-full top-0 left-0 w-full object-cover"
        />
        <div className="container h-full flex items-end relative z-10">
          <div className="lg:ml-[150px] animate-fade-up animate-duration-500 animate-ease-in-out animate-delay-150 bg-site-conversion max-sm:-mt-[50px] sm:-mb-[200px] md:-mb-[120px] max-w-screen-sm p-6 sm:p-10 xl:px-16 xl:py-12">
            <h1 className="text-3xl text-white font-site-accent pb-6 font-bold">{fields.title}</h1>
            <Content className="content">{fields.description}</Content>
          </div>
        </div>
      </div>
    </section>
  )
}
