import type { Page_Flexcontent_Flex_Posts, Post as PostProps } from '~/graphql/types'
import Post from './post-types/Post'
import { useInView } from 'framer-motion'
import { useRef } from 'react'
import clsx from 'clsx'

export default function NewsLatest({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const titleRef = useRef(null)
  const contentRef = useRef(null)
  const backgroundOneRef = useRef(null)
  const backgroundTwoRef = useRef(null)

  const titleVisible = useInView(titleRef, { once: true })
  const contentVisible = useInView(contentRef, { once: true })
  const backgroundOneVisible = useInView(backgroundOneRef, { once: true })
  const backgroundTwoVisible = useInView(backgroundTwoRef, { once: true })

  return (
    <section data-component="NewsLatest" className="relative overflow-hidden">
      <div className="container section relative">
        <svg
          ref={backgroundOneRef}
          className={clsx(
            backgroundOneVisible && 'animate-fade-left animate-delay-500 animate-duration-500 animate-ease-in-out',
            backgroundOneVisible ? 'opacity-100' : 'opacity-0',
            'absolute -right-20 lg:right-0 w-[400px] lg:w-[556px] -top-10'
          )}
          viewBox="0 0 556 731"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.2"
            d="M382.886 491.411L301.421 582.149L221.993 102.972C218.598 82.5816 214.864 68.6481 210.791 61.1716C206.718 53.0154 200.269 48.9373 191.443 48.9373C184.654 48.9373 176.847 54.7146 168.022 66.2692C159.197 77.1441 148.335 100.593 135.436 136.616L121.179 178.417H101.831L127.289 103.992C141.546 62.5309 159.197 35.0037 180.242 21.4101C201.287 7.1367 223.35 0 246.432 0C270.193 0 288.523 7.1367 301.421 21.4101C314.999 35.0037 324.503 60.8317 329.934 98.894L382.886 491.411ZM152.747 682.063C183.297 666.43 215.204 645.02 248.469 617.833C281.734 591.325 316.017 555.302 351.319 509.763C390.694 460.146 425.995 405.092 457.223 344.6C488.452 284.108 510.855 223.956 524.432 164.144C511.534 156.667 498.296 147.831 484.718 137.636C471.819 126.761 460.957 114.527 452.132 100.933C443.985 87.3394 440.252 72.0465 440.93 55.0544C442.288 37.3826 448.059 23.7889 458.242 14.2734C468.425 4.75779 482.002 0 498.974 0C518.662 0 532.918 7.47651 541.744 22.4296C551.248 36.7029 556 57.7731 556 85.6402C556 119.624 550.23 156.327 538.689 195.749C527.827 234.491 512.552 274.252 492.864 315.033C473.856 355.815 452.471 395.236 428.711 433.298C404.95 470.681 380.51 505.005 355.392 536.271C341.136 553.942 323.145 573.313 301.421 594.383C280.376 616.133 256.276 637.204 229.121 657.594C202.645 677.985 173.114 694.977 140.527 708.57C122.198 716.727 105.905 722.504 91.6483 725.902C78.0708 729.301 66.8693 731 58.0439 731C41.7509 731 28.1734 726.922 17.3113 718.766C5.77044 710.609 0 699.055 0 684.102C0 668.469 6.10989 655.895 18.3297 646.379C29.8706 636.864 43.7875 632.106 60.0806 632.106C77.7314 632.106 94.0244 636.864 108.96 646.379C124.574 656.575 139.17 668.469 152.747 682.063Z"
            fill="#7E826A"
          />
        </svg>

        <svg
          ref={backgroundTwoRef}
          className={clsx(
            backgroundTwoVisible && 'animate-fade-right animate-delay-500 animate-duration-500 animate-ease-in-out',
            backgroundTwoVisible ? 'opacity-100' : 'opacity-0',
            'absolute -left-40 lg:-left-28 w-[360px] lg:w-[488px] -bottom-[50px]'
          )}
          viewBox="0 0 596 621"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.2"
            d="M298.422 0C357.993 0 409.978 12.0937 454.376 36.2813C499.336 60.4688 534.18 95.625 558.908 141.75C583.636 187.313 596 241.875 596 305.438C596 367.313 583.355 422.156 558.065 469.969C532.775 517.781 497.369 554.906 451.847 581.344C406.887 607.781 355.464 621 297.578 621C238.007 621 185.741 608.906 140.781 584.719C96.3828 560.531 61.8199 525.656 37.0919 480.094C12.364 433.969 0 379.125 0 315.563C0 253.688 12.645 198.844 37.9349 151.031C63.2249 103.219 98.3498 66.0938 143.31 39.6563C188.832 13.2188 240.536 0 298.422 0ZM295.049 15.1875C264.14 15.1875 236.883 27.8437 213.279 53.1563C189.675 77.9063 171.41 112.781 158.484 157.781C146.12 202.781 139.938 254.25 139.938 312.188C139.938 370.688 146.963 422.156 161.013 466.594C175.625 511.031 195.014 545.344 219.18 569.531C243.908 593.719 271.165 605.813 300.95 605.813C331.86 605.813 359.117 593.438 382.721 568.688C406.325 543.375 424.309 508.219 436.673 463.219C449.599 418.219 456.062 366.75 456.062 308.813C456.062 250.313 448.756 198.844 434.144 154.406C420.094 109.969 400.705 75.6563 375.977 51.4688C351.811 27.2813 324.835 15.1875 295.049 15.1875Z"
            fill="#D29F89"
          />
        </svg>

        <div
          ref={titleRef}
          className={clsx(
            titleVisible && 'animate-fade-up animate-delay-100 animate-duration-500 animate-ease-in-out',
            titleVisible ? 'opacity-100' : 'opacity-0',
            'pb-8 lg:pb-14 lg:pt-10'
          )}
        >
          <h2 className="text-5xl sm:text-7xl xl:text-9xl text-site-text font-bold font-site-accent">{fields.title}</h2>
          <h3 className="font-site-accent text-4xl sm:text-5xl xl:text-6xl text-site-text italic pl-10 sm:pl-24 md:pl-48">
            {fields.subtitle}
          </h3>
        </div>

        <div
          ref={contentRef}
          className={clsx(
            contentVisible && 'animate-fade-up animate-delay-300 animate-duration-500 animate-ease-in-out',
            contentVisible ? 'opacity-100' : 'opacity-0',
            'grid lg:pb-10 max-w-screen-lg mx-auto grid-cols-1 sm:grid-cols-2 gap-6 sm:gap-4 lg:gap-12'
          )}
        >
          {fields?.posts?.edges?.slice(0, 2).map((item) => (
            <div key={item.node.databaseId}>
              <Post data={item?.node as PostProps} />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
