import { LosseLink, useLoaderData } from '@ubo/losse-sjedel'
import type { HeaderLoaderData } from './Header'
import clsx from 'clsx'

export default function Menu() {
  const { header } = useLoaderData<HeaderLoaderData>()

  return (
    <>
      {header?.menuHeader?.map((edge, index) => {
        return (
          <li className="relative max-lg:py-2" key={index}>
            <div className="flex flex-wrap items-center">
              <LosseLink
                // when item is last add class
                className={clsx(
                  index === header.menuHeader.length - 1 && 'btn--conversion',
                  index === header.menuHeader.length - 2 && 'btn',
                  index !== header.menuHeader.length - 1 &&
                  index !== header.menuHeader.length - 2 &&
                  'text-3xl font-site-accent text-site-text font-bold btn--pseudo lg:text-base xl:text-lg 2xl:text-xl lg:font-bold px-2',
                  'group'
                )}
                to={edge?.link?.url || '/'}
              >
                <span className="relative">
                  {edge?.link?.title}
                  {index !== header.menuHeader.length - 1 && (
                    <div className="absolute group-hover:w-full w-0 -bottom-[3px] left-0 smooth h-[3px] bg-site-base" />
                  )}
                </span>
              </LosseLink>
            </div>
          </li>
        )
      })}
    </>
  )
}
