import { FormDuufGroup, FormDuufSubmit } from '@ubo/form-duuf'
import clsx from 'clsx'
import { useInView } from 'framer-motion'
import { useRef } from 'react'
import Content from '~/components/elements/Content'
import Form from '~/components/elements/Form'
import Loading from '~/components/elements/Loading'
import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'

export default function FormNewsletter({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  const titleRef = useRef(null)
  const formRef = useRef(null)

  const titleVisible = useInView(titleRef, { once: true })
  const formVisible = useInView(formRef, { once: true })

  return (
    <section id="inschrijven" data-component="FormNewsletter" className="bg-site-accent lg:py-5">
      <div className="container section">
        <h2
          ref={titleRef}
          className={clsx(
            titleVisible && 'animate-fade-up animate-delay-100 animate-duration-500 animate-ease-in-out',
            titleVisible ? 'opacity-100' : 'opacity-0',
            'text-4xl pb-2 text-center font-site-accent font-bold'
          )}
        >
          {fields.title}
        </h2>
        <h3
          className={clsx(
            titleVisible && 'animate-fade-up animate-delay-300 animate-duration-500 animate-ease-in-out',
            titleVisible ? 'opacity-100' : 'opacity-0',
            'text-4xl text-center font-site-accent font-light italic'
          )}
        >
          {fields.subtitle}
        </h3>
        <div className="mt-4 text-center text-sm">{fields?.extraInfo}</div>
        <div
          ref={formRef}
          className={clsx(
            formVisible && 'animate-fade-up animate-delay-500 animate-duration-500 animate-ease-in-out',
            formVisible ? 'opacity-100' : 'opacity-0',
            'flex justify-center pt-12'
          )}
        >
          <Form
            data={fields.form}
            renderLoading={() => <Loading />}
            renderStatus={({ content }) => <Content className="content">{content}</Content>}
            generate={false}
            scrollToConfirm={false}
          >
            <div className={clsx('flex items-center flex-col', fields?.form?.formId !== 3 && 'md:flex-row md:gap-4')}>
              <FormDuufGroup />
              <FormDuufSubmit />
            </div>
          </Form>
        </div>
      </div>
    </section>
  )
}
