import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useInView } from 'framer-motion'
import { useRef } from 'react'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentHouses({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const titleRef = useRef(null)
  const contentRef = useRef(null)

  const titleVisible = useInView(titleRef, { once: true })
  const contentVisible = useInView(contentRef, { once: true })

  return (
    <section data-component="ContentHouses" className="section">
      <div className="container pb-8 md:pb-10 xl:pb-20 xl:pt-16">
        <div
          ref={titleRef}
          className={clsx(
            titleVisible && 'animate-fade-up animate-delay-100 animate-duration-500 animate-ease-in-out',
            titleVisible ? 'opacity-100' : 'opacity-0',
            'grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-10 lg:gap-20'
          )}
        >
          <div className="relative flex flex-col justify-end">
            <svg
              className="w-[750px] pointer-events-none absolute -left-[110px] -top-[50px]"
              viewBox="0 0 754 785"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.2"
                d="M377.533 0C452.898 0 518.664 15.2876 574.832 45.8628C631.71 76.438 675.792 120.879 707.075 179.185C738.358 236.78 754 305.752 754 386.101C754 464.316 738.003 533.644 706.008 594.083C674.014 654.522 629.222 701.452 571.632 734.871C514.753 768.29 449.698 785 376.467 785C301.102 785 234.981 769.712 178.102 739.137C121.934 708.562 78.2084 664.477 46.925 606.882C15.6417 548.576 0 479.248 0 398.899C0 320.684 15.9972 251.356 47.9915 190.917C79.9858 130.478 124.422 83.5485 181.301 50.1291C238.891 16.7097 304.302 0 377.533 0ZM373.267 19.1984C334.163 19.1984 299.68 35.197 269.819 67.1943C239.958 98.4805 216.851 142.566 200.498 199.45C184.856 256.334 177.035 321.395 177.035 394.633C177.035 468.582 185.923 533.644 203.697 589.817C222.183 645.99 246.712 689.364 277.284 719.939C308.568 750.514 343.05 765.802 380.733 765.802C419.837 765.802 454.32 750.159 484.181 718.872C514.042 686.875 536.794 642.434 552.436 585.55C568.788 528.666 576.965 463.605 576.965 390.367C576.965 316.418 567.722 251.356 549.236 195.183C531.462 139.01 506.933 95.6363 475.649 65.0612C445.077 34.486 410.95 19.1984 373.267 19.1984Z"
                fill="#D29F89"
              />
            </svg>

            <h2 className="text-5xl sm:text-6xl lg:text-7xl xl:text-[120px] lg:pb-3 relative z-10 leading-[1.2] font-site-accent font-bold text-site-text">
              {fields.title}
            </h2>
            <h3 className="font-site-accent pointer-events-none z-10 relative left-20 lg:left-[100px] xl:left-[180px] font-light text-site-text text-3xl sm:text-4xl lg:text-5xl xl:text-6xl">
              {fields.subtitle}
            </h3>
          </div>
          <div className="flex relative z-10 flex-col justify-end">
            <Content className="content">{fields.description}</Content>
            {fields.links && (
              <div className="flex items-center gap-2 mt-4">
                {fields.links.map((link, index) => (
                  <Button
                    target={link.link.target}
                    className={clsx(index >= 1 && 'btn--alt', 'btn--alt')}
                    key={index}
                    to={link?.link?.url || '/'}
                  >
                    {link?.link?.title}
                  </Button>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <div ref={contentRef}>
        {fields.items.map((item, index) => (
          <div
            className={clsx(
              index === 0 && 'bg-site-base animate-delay-300',
              index === 1 && 'bg-site-accent animate-delay-500',
              index === 2 && 'bg-site-conversion animate-delay-700',
              contentVisible && 'animate-fade-up animate-duration-500 animate-ease-in-out',
              contentVisible ? 'opacity-100' : 'opacity-0',
              'relative z-20'
            )}
            key={index}
          >
            <div className="container py-6 xs:py-9">
              <div className="grid grid-cols-1 sm:grid-cols-7 gap-5 sm:gap-10 lg:gap-20 xl:gap-[140px]">
                <div className="col-span-1 sm:col-span-4 relative xl:pl-14 flex flex-col justify-center gap-3 sm:gap-1 md:gap-2 lg:gap-3">
                  <h4 className="text-white relative pl-4 xl:pl-6 text-3xl md:text-4xl xl:text-5xl font-bold font-site-accent">
                    {index === 2 && (
                      <div className="absolute left-0 top-0 h-[35px] md:h-[40px] lg:h-[50px] xl:h-[67px] w-[6px] lg:w-[8px] bg-black" />
                    )}
                    {item.title}
                  </h4>
                  {index === 0 && (
                    <div className="grid grid-cols-2 gap-5 xl:gap-7">
                      <div className="h-[6px] lg:h-[10px] bg-black" />
                      <div className="h-[6px] lg:h-[10px] bg-black" />
                    </div>
                  )}
                  {index === 1 && (
                    <div className="grid grid-cols-5 gap-5 xl:gap-7">
                      <div className="h-[6px] lg:h-[10px] bg-black" />
                      <div className="h-[6px] lg:h-[10px] bg-black" />
                      <div className="h-[6px] lg:h-[10px] bg-black" />
                      <div className="h-[6px] lg:h-[10px] bg-black" />
                      <div className="h-[6px] lg:h-[10px] bg-black" />
                    </div>
                  )}
                  {index === 2 && (
                    <div className="grid grid-cols-1 gap-7">
                      <div className="h-[6px] lg:h-[10px] bg-black" />
                    </div>
                  )}
                  <h5 className="text-xl md:text-2xl lg:text-3xl italic font-site-accent text-right pr-4 xl:pr-6">{item.subtitle}</h5>
                  <div className="max-sm:pt-4 max-md:pt-2 max-sm:flex max-sm:justify-end md:mt-20">
                    <Button className="md:absolute xl:left-14 bottom-0 xl:bottom-10 btn--outline" to={item.link.url}>
                      {item.link.title}
                    </Button>
                  </div>
                </div>
                <div className="col-span-1 max-sm:-order-1 sm:col-span-3">
                  <LossePlaatjie maxwidth={500} src={item.image} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}
