import type { Page_Flexcontent_Flex_Posts, Post as PostProps, Maybe, RootQueryToPostConnection } from '~/graphql/types'
import Post from './post-types/Post'
import { useInView } from 'framer-motion'
import { useRef } from 'react'
import clsx from 'clsx'

interface NewsOtherProps extends Page_Flexcontent_Flex_Posts {
  subdata: {
    Other: Maybe<RootQueryToPostConnection>
  }
}

export default function NewsOther({ fields }: { fields: NewsOtherProps }) {
  const titleRef = useRef(null)
  const contentRef = useRef(null)

  const titleVisible = useInView(titleRef, { once: true })
  const contentVisible = useInView(contentRef, { once: true })

  if (fields?.subdata?.Other?.edges?.length === 0) return null

  return (
    <section data-component="NewsOther" className="max-sm:relative max-sm:overflow-hidden">
      <div className="container section relative">
        <div
          ref={titleRef}
          className={clsx(
            titleVisible && 'animate-fade-up animate-delay-100 animate-duration-500 animate-ease-in-out',
            titleVisible ? 'opacity-100' : 'opacity-0',
            'pb-8 lg:pb-14 max-w-screen-lg mx-auto'
          )}
        >
          <h2 className="text-3xl sm:text-4xl xl:text-5xl text-site-text font-bold font-site-accent">{fields.title}</h2>
        </div>

        <div
          ref={contentRef}
          className={clsx(
            contentVisible && 'animate-fade-up animate-delay-300 animate-duration-500 animate-ease-in-out',
            contentVisible ? 'opacity-100' : 'opacity-0',
            'grid max-w-screen-lg mx-auto grid-cols-1 sm:grid-cols-2 gap-6 sm:gap-4 lg:gap-12'
          )}
        >
          {fields?.subdata?.Other?.edges?.map((item) => (
            <div key={item.node.databaseId}>
              <Post data={item?.node as PostProps} />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
