import { Root, rootLoader as loader, useLoaderData } from '@ubo/losse-sjedel';
import type { LinksFunction, RootProps } from '@ubo/losse-sjedel';
import PageNotFound from '~/components/layout/PageNotFound';
import styles from '~/global.css';
import FlexContent from './graphql/FlexContent';
import Queries from './graphql/Queries';
import PostTypes from './graphql/PostTypes';

export const flexData = {
  flexContent: FlexContent,
  queries: Queries,
  postTypes: PostTypes
};

export const links: LinksFunction = () => {
  return [
  {
    rel: 'preload',
    as: 'style',
    href: styles
  },
  {
    href: '/static/nprogress.min.css',
    rel: 'stylesheet',
    async: true
  },
  {
    rel: 'preload',
    as: 'style',
    href: styles
  },
  {
    href: styles,
    rel: 'stylesheet'
  },
  {
    href: '/manifest.json',
    rel: 'manifest'
  },
  {
    rel: 'icon',
    href: '/favicon.png',
    type: 'image/png'
  },
  {
    rel: 'stylesheet',
    href: 'https://use.typekit.net/trr5msw.css'
  },
  {
    rel: 'preconnect',
    href: 'https://fonts.gstatic.com'
  },
  {
    rel: 'preconnect',
    href: 'https://fonts.googleapis.com'
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,700;1,400;1,700&display=block'
  }];

};

export { loader };

export function CatchBoundary() {
  return <PageNotFound />;
}

export default function App() {
  const data = useLoaderData<RootProps>();

  // @ts-ignore
  return <Root {...data} />;
}