import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import BannerDefault from './BannerDefault'
import BannerHome from './BannerHome'
import BannerDetail from './BannerDetail'
import BannerHouse from './BannerHouse'

export default function BannerShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: BannerDefault,
    home: BannerHome,
    detail: BannerDetail,
    house: BannerHouse
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
