import clsx from 'clsx'
import { useInView } from 'framer-motion'
import { useRef } from 'react'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentDual({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const contentRef = useRef(null)

  const contentVisible = useInView(contentRef, { once: true })

  return (
    <section data-component="ContentDual" className="section">
      <div className="container">
        <div
          ref={contentRef}
          className={clsx(
            contentVisible && 'animate-fade-up animate-delay-100 animate-duration-500 animate-ease-in-out',
            contentVisible ? 'opacity-100' : 'opacity-0',
            'max-w-screen-lg mx-auto flex justify-center flex-col md:flex-row lg:pl-10 gap-6 lg:gap-16'
          )}
        >
          <Content className="content min-w-[320px]">{fields.extradescription}</Content>
          <Content className="content">{fields.description}</Content>
        </div>
      </div>
    </section>
  )
}
