import { LossePlaatjie } from '@ubo/losse-sjedel'
import type { Page_Flexcontent_Flex_Form } from '~/graphql/types'
import useGeneralInfo from '~/hooks/useGeneralInfo'

export default function FormContact({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  const info = useGeneralInfo()

  return (
    <section data-component="FormContact" className="section">
      <div className="container md:py-10 lg:py-20">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
          <div>
            <h1 className="text-7xl animate-fade-up animate-delay-100 animate-duration-500 animate-ease-in-out sm:text-8xl xl:text-9xl font-bold z-10 relative font-site-accent text-site-text">
              {fields.title}
            </h1>
            <LossePlaatjie
              maxwidth={1000}
              className="absolute md:block hidden animate-fade-up animate-duration-500 animate-ease-in-out !opacity-10 lg:!opacity-75 w-[300px] md:w-[570px] mix-blend-multiply top-[200px]"
              src={fields.image}
            />
          </div>
          <div className="lg:pt-10 animate-fade-up animate-duration-500 animate-delay-300 animate-ease-in-out lg:pr-[50px] xl:pr-[120px]">
            <h2 className="text-2xl sm:text-3xl font-bold font-site-accent">{fields.subtitle}</h2>
            <div className="font-light font-site-base py-8">{fields.description}</div>
            <div className="flex flex-row gap-5 sm:gap-20">
              {info.estateagents.map((agent, index) => (
                <div key={index}>
                  <div className="text-lg xs:text-xl italic font-site-accent font-light">{agent.title}</div>
                  <div className="flex flex-col">
                    <a target="_blank" rel="noreferrer" className="text-black hover:underline text-sm xs:text-base" href={agent.phone.url}>
                      <strong className="text-base xs:text-lg pr-1 font-site-accent font-bold text-black">T:</strong>
                      {agent.phone.title}
                    </a>
                    <a target="_blank" rel="noreferrer" className="text-black hover:underline text-sm xs:text-base" href={agent.email.url}>
                      <strong className="text-base xs:text-lg pr-1 font-site-accent font-bold text-black">E:</strong>
                      {agent.email.title}
                    </a>
                  </div>
                  <div className="text-lg xs:text-xl pb-1 italic pt-4 font-site-accent font-light">{agent.subtitle}</div>
                  <div className="text-base font-site-base text-black font-light">{agent.name}</div>
                </div>
              ))}
            </div>
            <div className="bg-site-accent-light flex justify-center">
              <LossePlaatjie
                maxwidth={1000}
                className="block md:hidden animate-fade-up animate-duration-500 animate-ease-in-out opacity-10 lg:opacity-75 w-[300px] md:w-[570px] mix-blend-multiply top-[200px]"
                src={fields.image}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
