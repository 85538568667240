import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import NewsLatest from './NewsLatest'
import NewsOverview from './NewsOverview'
import NewsOther from './NewsOther'

export default function PostsShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    newslatest: NewsLatest,
    newsoverview: NewsOverview,
    newsother: NewsOther
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
