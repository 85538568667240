import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { useInView } from 'framer-motion'
import { useRef } from 'react'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentUsp({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const contentRef = useRef(null)

  const contentVisible = useInView(contentRef, { once: true })

  return (
    <section data-component="ContentUsp" className="section relative z-20">
      <div
        ref={contentRef}
        className={clsx(
          contentVisible && 'animate-fade-up animate-delay-100 animate-duration-500 animate-ease-in-out',
          contentVisible ? 'opacity-100' : 'opacity-0',
          'container'
        )}
      >
        <div className="max-w-screen-lg max-lg:w-fit mx-auto bg-site-accent relative z-40 p-6 xs:p-12">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 lg:gap-4">
            {fields.items.map((item, index) => (
              <div
                className={clsx(
                  contentVisible &&
                    `animate-fade-up ${index === 0 && 'animate-delay-300'} ${index === 1 && 'animate-delay-500'} ${
                      index === 2 && 'animate-delay-700'
                    } animate-duration-500 animate-ease-in-out`,
                  contentVisible ? 'opacity-100' : 'opacity-0',
                  index === 1 ? 'justify-end' : 'justify-start',
                  ' flex flex-col lg:h-[280px]'
                )}
                key={index}
              >
                <div className="flex justify-center">
                  <LossePlaatjie maxwidth={500} src={item.image} />
                </div>
                <div className="pt-2 pb-3">
                  <h3 className="text-center text-3xl font-site-accent italic">
                    <strong className="not-italic">{item.title}</strong>
                    {` ${item.subtitle}`}
                  </h3>
                </div>
                <Content className="content black">{item.description}</Content>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
