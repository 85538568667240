import clsx from 'clsx'
import { useInView } from 'framer-motion'
import { useRef } from 'react'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentDefault({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const contentRef = useRef(null)

  const contentVisible = useInView(contentRef, { once: true })

  return (
    <section data-component="ContentDefault" className="section">
      <div className="container">
        <div
          ref={contentRef}
          className={clsx(
            contentVisible && 'animate-fade-up animate-delay-100 animate-duration-500 animate-ease-in-out',
            contentVisible ? 'opacity-100' : 'opacity-0',
            'max-w-screen-md mx-auto'
          )}
        >
          {fields.description && (
            <Content className="content lg:children-p:text-l lg:children-p:leading-[30px]">{fields?.description}</Content>
          )}

          {fields.links && (
            <div className="flex items-center gap-2 mt-4">
              {fields.links.map((link, index) => (
                <Button className={clsx(index >= 1 && 'btn--alt', 'btn')} key={index} to={link?.link?.url || '/'}>
                  {link?.link?.title}
                </Button>
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
