import { useMatches } from '@ubo/losse-sjedel'
import type { FormDuufProps } from '@ubo/form-duuf'
import FormDuuf from '@ubo/form-duuf'

type Props = FormDuufProps & {
  className?: string
}

export default function Form(props: Props) {
  const matches = useMatches()
  const formId = props.data.formId
  const apiUrl = `${matches[0].data.config.wordpressUrl}/wp-json/gf/v2/forms/${formId}`

  return <FormDuuf apiUrl={apiUrl} {...props} className={props?.className || 'relative'} />
}
