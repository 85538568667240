import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'

export default function BannerHouse({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section data-component="BannerHouse">
      <div className="max-w-[2000px] mx-auto h-[400px] sm:h-[calc(100dvh_-_100px)]">
        <LossePlaatjie
          loading="eager"
          maxwidth={2000}
          src={fields.image}
          className="animate-fade-up animate-duration-500 animate-ease-in-out h-full object-cover w-full"
        />
      </div>
      <div className="container">
        <div className="lg:ml-[150px] -mt-[120px] sm:-mt-[200px] animate-fade-up animate-duration-500 animate-ease-in-out animate-delay-150 bg-site-conversion p-8 xs:p-12 max-w-screen-sm">
          <h1 className="font-site-accent text-4xl xs:text-5xl sm:text-6xl font-bold text-site-text pb-2">{fields.title}</h1>
          <div className="flex justify-end">
            <h2 className="font-site-accent italic text-2xl xs:text-3xl sm:text-4xl text-site-text">{fields.subtitle}</h2>
          </div>
          <Content className="pt-6 children-p:text-site-text sm:children-p:text-xl children-strong:children-p:mb-2 children-strong:children-p:block sm:children-strong:children-p:text-2xl">
            {fields.description}
          </Content>
          <div className="flex">
            {fields.links && (
              <div className="flex sm:flex-row flex-col sm:items-center gap-4 mt-9">
                {fields.links.map((link, index) => (
                  <Button
                    className={clsx(index >= 1 && 'btn--outline', 'btn--accent gap-3')}
                    key={index}
                    to={link?.link?.url || '/'}
                    target={link?.link?.target}
                  >
                    {link?.link?.title}
                    {link.link.title.includes('Download') && (
                      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_617_3162)">
                          <path
                            d="M9.52532 15.0417C9.83732 15.0422 10.1463 14.9811 10.4346 14.8618C10.7229 14.7425 10.9848 14.5674 11.2052 14.3466L14.3078 11.2417L13.1884 10.1254L10.3122 13.0023L10.2917 0H8.70832L8.7289 12.9897L5.86307 10.1238L4.74365 11.2417L7.84619 14.3442C8.06634 14.5653 8.32801 14.7408 8.61617 14.8605C8.90433 14.9802 9.21329 15.0417 9.52532 15.0417Z"
                            fill="white"
                          />
                          <path
                            d="M17.4167 12.6666V16.6249C17.4167 16.8349 17.3333 17.0362 17.1848 17.1847C17.0363 17.3331 16.835 17.4166 16.625 17.4166H2.375C2.16504 17.4166 1.96367 17.3331 1.81521 17.1847C1.66674 17.0362 1.58333 16.8349 1.58333 16.6249V12.6666H0V16.6249C0 17.2548 0.250222 17.8589 0.695621 18.3043C1.14102 18.7497 1.74511 18.9999 2.375 18.9999H16.625C17.2549 18.9999 17.859 18.7497 18.3044 18.3043C18.7498 17.8589 19 17.2548 19 16.6249V12.6666H17.4167Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_617_3162">
                            <rect width="19" height="19" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    )}
                  </Button>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
