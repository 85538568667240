import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import type { Post as Props } from '~/graphql/types'

export default function Post({ data }: { data: Props }) {
  if (!data.title) return null

  return (
    <LosseLink className="group" to={data.uri}>
      <div className="aspect-[4/3] lg:h-[360px] w-full relative">
        <LossePlaatjie
          src={data.featuredImage?.node}
          className="w-full h-full aspect-[4/3] object-cover"
          placeholder="blur"
          maxwidth={600}
          title={data.title}
        />
        <div className="p-3 lg:p-4 group-hover:bg-site-base-light smooth bg-site-accent rounded-full absolute -bottom-6 lg:-bottom-7 aspect-square right-6 lg:right-10">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="fi-br-angle-up" clipPath="url(#clip0_617_1758)">
              <path
                id="Vector"
                d="M6.00002 22.5004C6.00006 22.3033 6.03894 22.1082 6.11444 21.9261C6.18993 21.7441 6.30057 21.5787 6.44001 21.4394L14.111 13.7684C14.3432 13.5362 14.5274 13.2606 14.6531 12.9572C14.7788 12.6539 14.8435 12.3287 14.8435 12.0004C14.8435 11.672 14.7788 11.3469 14.6531 11.0435C14.5274 10.7402 14.3432 10.4645 14.111 10.2324L6.44901 2.57039C6.17578 2.28748 6.02459 1.90858 6.028 1.51529C6.03142 1.12199 6.18918 0.745772 6.46729 0.46766C6.7454 0.189548 7.12162 0.0317942 7.51491 0.0283766C7.90821 0.024959 8.28711 0.176151 8.57001 0.449388L16.232 8.11139C17.2622 9.14348 17.8408 10.5421 17.8408 12.0004C17.8408 13.4586 17.2622 14.8573 16.232 15.8894L8.56001 23.5614C8.35018 23.771 8.08293 23.9136 7.79204 23.9714C7.50115 24.0291 7.19967 23.9993 6.92571 23.8858C6.65174 23.7722 6.41758 23.58 6.25281 23.3334C6.08805 23.0868 6.00008 22.797 6.00002 22.5004Z"
                fill="#F7E5D0"
              />
            </g>
            <defs>
              <clipPath id="clip0_617_1758">
                <rect width="24" height="24" fill="white" transform="translate(24) rotate(90)" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
      <h2 className="text-xl lg:pl-5 lg:text-2xl pt-5 font-bold font-site-accent text-site-text pr-10 lg:pr-20 line-clamp-2">
        {data.title}
      </h2>
    </LosseLink>
  )
}
