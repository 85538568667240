import { LossePlaatjie } from '@ubo/losse-sjedel'
import Slider, { Slide } from '~/components/elements/Slider'
import type { Page_Flexcontent_Flex_Slider } from '~/graphql/types'
import { Navigation } from 'swiper/modules'
import { useInView } from 'framer-motion'
import { useRef, useState } from 'react'
import FsLightbox from 'fslightbox-react'
import clsx from 'clsx'

export default function SliderDefault({ fields }: { fields: Page_Flexcontent_Flex_Slider }) {
  const [toggler, setToggler] = useState(false)
  const [currentSlide, setCurrentSlide] = useState(0)

  const titleRef = useRef(null)
  const contentRef = useRef(null)

  const titleVisible = useInView(titleRef, { once: true })
  const contentVisible = useInView(contentRef, { once: true })

  return (
    <section data-component="SliderDefault" className="section" id="impressies">
      <div className="container">
        <div
          ref={titleRef}
          className={clsx(
            titleVisible && 'animate-fade-up animate-delay-100 animate-duration-500 animate-ease-in-out',
            titleVisible ? 'opacity-100' : 'opacity-0',
            'max-w-screen-sm mx-auto relative z-20'
          )}
        >
          <h2 className="text-5xl sm:text-7xl md:text-8xl font-site-accent font-bold text-site-text">{fields.title}</h2>
          <h3 className="text-2xl xs:text-3xl sm:text-5xl md:text-6xl whitespace-nowrap font-site-accent pl-14 text-site-text">
            {fields.subtitle}
          </h3>
        </div>
        <div
          ref={contentRef}
          className={clsx(
            contentVisible && 'animate-fade-up animate-delay-300 animate-duration-500 animate-ease-in-out',
            contentVisible ? 'opacity-100' : 'opacity-0',
            'max-w-screen-lg relative pt-8 lg:pt-20 mx-auto'
          )}
        >
          <svg
            className="w-[300px] xs:w-[380px] sm:w-[600px] lg:w-[754px] absolute left-0 right-0 mx-auto z-10 -top-[100px] sm:-top-[180px] lg:-top-[220px]"
            viewBox="0 0 754 785"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.2"
              d="M377.533 0C452.898 0 518.664 15.2876 574.832 45.8628C631.71 76.438 675.792 120.879 707.075 179.185C738.358 236.78 754 305.752 754 386.101C754 464.316 738.003 533.644 706.008 594.083C674.014 654.522 629.222 701.452 571.632 734.871C514.753 768.29 449.698 785 376.467 785C301.102 785 234.981 769.712 178.102 739.137C121.934 708.562 78.2084 664.477 46.925 606.882C15.6417 548.576 0 479.248 0 398.899C0 320.684 15.9972 251.356 47.9915 190.917C79.9858 130.478 124.422 83.5485 181.301 50.1291C238.891 16.7097 304.302 0 377.533 0ZM373.267 19.1984C334.163 19.1984 299.68 35.197 269.819 67.1943C239.958 98.4805 216.851 142.566 200.498 199.45C184.856 256.334 177.035 321.395 177.035 394.633C177.035 468.582 185.923 533.644 203.697 589.817C222.183 645.99 246.712 689.364 277.284 719.939C308.568 750.514 343.05 765.802 380.733 765.802C419.837 765.802 454.32 750.159 484.181 718.872C514.042 686.875 536.794 642.434 552.436 585.55C568.788 528.666 576.965 463.605 576.965 390.367C576.965 316.418 567.722 251.356 549.236 195.183C531.462 139.01 506.933 95.6363 475.649 65.0612C445.077 34.486 410.95 19.1984 373.267 19.1984Z"
              fill="#8D94A4"
            />
          </svg>
          <div className="relative z-20">
            <div
              className="bg-site-accent-light hover:bg-site-base-light smooth cursor-pointer flex justify-center items-center z-10 w-[45px] h-[80px] sm:w-[67px] sm:h-[117px] absolute left-0 top-0 bottom-0 my-auto"
              id="customsliderdefaultprev"
            >
              <svg width="36" height="24" viewBox="0 0 36 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_747_1570)">
                  <path
                    d="M27.943 -9.30179e-08L18.436 9.52C17.7819 10.1772 17.4147 11.0667 17.4147 11.994C17.4147 12.9213 17.7819 13.8108 18.436 14.468L27.954 24L30.071 21.879L20.554 12.348C20.4603 12.2542 20.4077 12.1271 20.4077 11.9945C20.4077 11.8619 20.4603 11.7348 20.554 11.641L30.06 2.121L27.943 -9.30179e-08Z"
                    fill="#3A3A3A"
                  />
                </g>
                <g clipPath="url(#clip1_747_1570)">
                  <path
                    d="M15.943 -9.30179e-08L6.43604 9.52C5.78191 10.1772 5.41468 11.0667 5.41468 11.994C5.41468 12.9213 5.78191 13.8108 6.43604 14.468L15.954 24L18.071 21.879L8.55404 12.348C8.46031 12.2542 8.40765 12.1271 8.40765 11.9945C8.40765 11.8619 8.46031 11.7348 8.55404 11.641L18.06 2.121L15.943 -9.30179e-08Z"
                    fill="#3A3A3A"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_747_1570">
                    <rect width="24" height="24" fill="white" transform="translate(36) rotate(90)" />
                  </clipPath>
                  <clipPath id="clip1_747_1570">
                    <rect width="24" height="24" fill="white" transform="translate(24) rotate(90)" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div
              className="bg-site-accent-light hover:bg-site-base-light smooth cursor-pointer flex justify-center items-center z-10 w-[45px] h-[80px] sm:w-[67px] sm:h-[117px] absolute right-0 top-0 bottom-0 my-auto"
              id="customsliderdefaultnext"
            >
              <svg width="36" height="24" viewBox="0 0 36 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_747_1571)">
                  <path
                    d="M8.0572 24L17.5642 14.48C18.2183 13.8228 18.5856 12.9333 18.5856 12.006C18.5856 11.0787 18.2183 10.1892 17.5642 9.532L8.0462 2.77611e-07L5.9292 2.121L15.4462 11.652C15.5399 11.7458 15.5926 11.8729 15.5926 12.0055C15.5926 12.1381 15.5399 12.2652 15.4462 12.359L5.9402 21.879L8.0572 24Z"
                    fill="#3A3A3A"
                  />
                </g>
                <g clipPath="url(#clip1_747_1571)">
                  <path
                    d="M20.0572 24L29.5642 14.48C30.2183 13.8228 30.5856 12.9333 30.5856 12.006C30.5856 11.0787 30.2183 10.1892 29.5642 9.532L20.0462 2.77611e-07L17.9292 2.121L27.4462 11.652C27.5399 11.7458 27.5926 11.8729 27.5926 12.0055C27.5926 12.1381 27.5399 12.2652 27.4462 12.359L17.9402 21.879L20.0572 24Z"
                    fill="#3A3A3A"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_747_1571">
                    <rect width="24" height="24" fill="white" transform="translate(0 24) rotate(-90)" />
                  </clipPath>
                  <clipPath id="clip1_747_1571">
                    <rect width="24" height="24" fill="white" transform="translate(12 24) rotate(-90)" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <Slider
              navigation={{
                prevEl: '#customsliderdefaultprev',
                nextEl: '#customsliderdefaultnext'
              }}
              modules={[Navigation]}
              slidesPerView={1}
            >
              {fields.gallery.map((item, index) => (
                <Slide key={index}>
                  <button
                    type="button"
                    onClick={() => {
                      setCurrentSlide(index + 1)
                      setToggler(!toggler)
                    }}
                    className="m-0 hover:opacity-70"
                  >
                    <LossePlaatjie maxwidth={1200} className="w-full h-full object-cover" src={item} />
                  </button>
                </Slide>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <FsLightbox sources={fields?.gallery?.map((image) => image?.mediaItemUrl)} slide={currentSlide} toggler={toggler} />
    </section>
  )
}
